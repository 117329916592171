<template>
  <v-dialog v-model="show" persistent max-width="700px" scrollable>
    <v-card max-height="80vh">
      <div class="d-flex">
        <div class="flex-grow-1">
          <v-card-title> Créer une campagne </v-card-title>
        </div>
        <div class="d-flex align-center">
          <v-icon class="mr-4 pa-1 m_brown" @click="show = false"
            >mdi-close</v-icon
          >
        </div>
      </div>
      <v-card-text>
        <v-stepper flat tile elevation="0" v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" class="primary-step">
              Type de campagne
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2" class="primary-step">
              Informations
            </v-stepper-step>
            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 3" step="3" class="primary-step">
              Détails
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4" class="primary-step">
              Valider
            </v-stepper-step>
          </v-stepper-header>

          <v-form ref="form" @submit.prevent="createCampaign()" class="form">
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-container fluid>
                  <h3>
                    Type de campagne choisi : {{ campaignType || "null" }}
                  </h3>
                  <v-radio-group v-model="campaignType" mandatory>
                    <v-radio
                      label="Auscultation"
                      value="Auscultation"
                    ></v-radio>
                    <v-radio label="Carottage" value="Carottage"> </v-radio>
                  </v-radio-group>
                </v-container>

                <HighButtonSlot class="right_btn" @click="e1 = 2"
                  >Continuer</HighButtonSlot
                >
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      autofocus
                      tabindex="1"
                      class="mt-1"
                      label="Nom de la campagne*"
                      outlined
                      clearable
                      v-model="form.name"
                      :rules="[$rules.required, $rules.min5Chars]"
                      dense
                    ></v-text-field>
                    <v-textarea
                      class="mt-0 m_textarea"
                      label="Description*"
                      tabindex="2"
                      outlined
                      clearable
                      auto-grow
                      v-model="form.description"
                      :rules="[$rules.required]"
                      rows="2"
                      :counter="500"
                    ></v-textarea>
                    <v-autocomplete
                      :items="customers"
                      item-text="name"
                      item-value="id"
                      tabindex="5"
                      label="Client*"
                      outlined
                      clearable
                      v-model="form.customerId"
                      prepend-inner-icon="mdi-account-search"
                      :rules="[$rules.required]"
                      dense
                    ></v-autocomplete>

                    <v-autocomplete
                      :items="engineers"
                      item-text="name"
                      tabindex="6"
                      item-value="id"
                      label="Ingénieur"
                      outlined
                      clearable
                      v-model="form.engineerId"
                      prepend-inner-icon="mdi-account-search"
                      dense
                    ></v-autocomplete>

                    <v-autocomplete
                      :items="studyManagers"
                      item-text="name"
                      tabindex="7"
                      item-value="id"
                      label="Chargé d'études*"
                      outlined
                      clearable
                      v-model="form.studyManagerId"
                      prepend-inner-icon="mdi-account-search"
                      :rules="[$rules.required]"
                      dense
                    ></v-autocomplete>

                    <v-autocomplete
                      v-if="campaignType === 'Carottage'"
                      :items="technicians"
                      item-text="name"
                      tabindex="8"
                      item-value="id"
                      label="Techniciens*"
                      outlined
                      clearable
                      v-model="form.technicianIds"
                      prepend-inner-icon="mdi-account-search"
                      :rules="[$rules.atLeastOneTechnician]"
                      multiple
                      small-chips
                      dense
                    ></v-autocomplete>
                    <v-select
                      v-if="campaignType === 'Carottage'"
                      :items="coringTypes"
                      tabindex="9"
                      item-text="name"
                      item-value="value"
                      label="Type de carottage*"
                      outlined
                      clearable
                      v-model="form.coringType"
                      :rules="[$rules.required]"
                      dense
                    ></v-select>

                    <v-select
                      v-if="campaignType === 'Auscultation'"
                      :items="operatingMode"
                      tabindex="8"
                      item-text="name"
                      item-value="value"
                      label="Mode opératoire de relevé de dégradations (optionnel)"
                      outlined
                      clearable
                      v-model="form.operatingMode"
                      dense
                    ></v-select>
                    <v-text-field
                      v-if="
                        campaignType === 'Auscultation' &&
                        form.operatingMode === 'not_standard'
                      "
                      tabindex="9"
                      label="Commentaire sur le mode opératoire"
                      v-model="form.operatingModeComment"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <MediumButtonSlot @click="e1 = 1">Retour</MediumButtonSlot>
                <HighButtonSlot class="right_btn" @click="validateForm()"
                  >Continuer</HighButtonSlot
                >
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-col cols="12" md="12">
                  <h3>Références dossier</h3>
                  <br>
                  <v-text-field
                    tabindex="3"
                    class="mt-1"
                    label="Référence dossier NextRoad*"
                    outlined
                    clearable
                    v-model="form.nextroadRef"
                    :rules="[rules.requiredOn3rdStep]"
                    dense
                  ></v-text-field>
                  <v-text-field
                    tabindex="4"
                    class="mt-1"
                    label="Référence commande client*"
                    outlined
                    clearable
                    v-model="form.customerOrderRef"
                    :rules="[rules.requiredOn3rdStep]"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="campaignType === 'Carottage'">
                  <h3>Valeurs par défaut - Carottage</h3>
                  <br>
                  <v-select
                    tabindex="10"
                    dense
                    class="form-inputs"
                    v-model="defaultCoreParams.structureType"
                    :items="stTypeField"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.id"
                    label="Type de Structure par défaut"
                    data-vv-name="select"
                    outlined
                    clearable
                  ></v-select>
                  <v-select
                    dense
                    tabindex="11"
                    class="form-inputs"
                    v-model="defaultCoreParams.isFullStructure"
                    :items="stCompleteField"
                    :item-text="(item) => item.name"
                    :item-value="(item) => item.bool"
                    label="Structure Complète par défaut"
                    data-vv-name="select"
                    outlined
                    required
                    clearable
                  ></v-select>
                  <v-select
                    dense
                    tabindex="12"
                    class="lateral-left"
                    :items="formDiameter"
                    v-model="defaultCoreParams.diameter"
                    label="Diamètre"
                    outlined
                    required
                    clearable
                  ></v-select>
                </v-col>
                <MediumButtonSlot @click="e1 = 2">Retour</MediumButtonSlot>
                <HighButtonSlot class="right_btn" @click="validateForm()"
                  >Continuer</HighButtonSlot
                >
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card class="mb-12" outlined>
                  <v-card-title>Récapitulatif</v-card-title>
                  <v-card-text>
                    <p>
                      <b>Type de campagne</b>
                      <span class="m_brown"> &nbsp; {{ campaignType }}</span>
                    </p>
                    <p>
                      <b>Nom</b>
                      <span class="m_brown"> &nbsp; {{ form.name }}</span>
                    </p>
                    <p>
                      <b>Client</b>
                      <span class="m_brown">
                        &nbsp;
                        {{ getNameById(form.customerId, customers) }}</span
                      >
                    </p>
                    <p>
                      <b>Ingénieur</b>
                      <span class="m_brown">
                        &nbsp;
                        {{ getNameById(form.engineerId, engineers) }}</span
                      >
                    </p>
                    <p>
                      <b>Chargé d'études</b>
                      <span class="m_brown">
                        &nbsp;
                        {{
                          getNameById(form.studyManagerId, studyManagers)
                        }}</span
                      >
                    </p>
                    <p v-if="campaignType === 'Carottage'">
                      <b>Type de carottage</b>
                      <span class="m_brown"> &nbsp; {{ form.coringType }}</span>
                    </p>
                    <p v-if="campaignType === 'Carottage'">
                      <b>Technicien</b>
                      <span class="m_brown">
                        &nbsp;
                        {{ getTechniciansToString() }}</span
                      >
                    </p>
                    <p
                      v-if="
                        campaignType === 'Auscultation' &&
                        form.operatingMode != null
                      "
                    >
                      <b>Mode opératoire</b>
                      <span class="m_brown">
                        &nbsp; {{ form.operatingMode }}</span
                      >
                    </p>

                    <p class="mb-2"></p>
                  </v-card-text>
                </v-card>

                <MediumButtonSlot @click="e1 = 3">Retour</MediumButtonSlot>
                <HighButtonSlot
                  class="right_btn"
                  type="submit"
                  :_loading="loading"
                  >Valider la création</HighButtonSlot
                >
              </v-stepper-content>
            </v-stepper-items>
          </v-form>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CampaignCreationModale",
  props: {
    workspaceId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      show: false,
      e1: 1,
      campaignType: null,
      operatingMode: [
        { name: "M1", value: "M1" },
        { name: "M2", value: "M2" },
        { name: "M3", value: "M3" },
        { name: "M4", value: "M4" },
        { name: "M6", value: "M6" },
        { name: "M7", value: "M7" },
        { name: "M2+", value: "M2+" },
        { name: "M3+", value: "M3+" },
        { name: "Non standard", value: "not_standard" },
      ],
      coringTypes: [
        { name: "Amiante", value: "CA" },
        {
          name: "Structurel",
          value: "CS",
          disabled: true,
        } /** Doesn't comes with V1 */,
      ],
      form: {
        name: "",
        description: "",
        coringType: "CA", // By default because only choice for V1
        operatingMode: null,
        operatingModeComment: null,
        customerId: null,
        engineerId: null,
        studyManagerId: null,
        technicianIds: [],
        nextroadRef: null,
        customerOrderRef: null,
      },
      customers: [],
      engineers: [],
      studyManagers: [],
      technicians: [],
      defaultCoreParams: {
        structureType: null,
        fullStructure: null,
        diameter: null,
      },
      stTypeField: [
        { id: 1, name: "Souple" },
        { id: 2, name: "Bitumineuse épaisse" },
        { id: 3, name: "Mixte" },
        { id: 4, name: "Rigide" },
      ],
      formDiameter: [50, 80, 100, 150],
      stCompleteField: [
        { bool: true, name: "Oui" },
        { bool: false, name: "Non" },
      ],
      rules: {
        requiredOn3rdStep: this.requiredOn3rdStep,
      },
      loading: false,
    };
  },
  mounted() {
    this.$api.users.findAll().then((users) => {
      this.engineers = users
        .filter((user) => user.job === "engineer")
        .map((user) => this.mapUserInformations(user));

      this.technicians = users
        .filter((user) => user.job === "technician")
        .map((user) => this.mapUserInformations(user));

      this.studyManagers = users
        .filter((user) => user.job === "study_manager")
        .map((user) => this.mapUserInformations(user));
    });

    this.$api.users.findByRole("ROLE_CUSTOMER_MAIN").then((users) => {
      this.customers = users.map((user) => this.mapUserInformations(user));
    });
  },
  methods: {
    requiredOn3rdStep(v) {
      return this.e1 < 3 || !!v || "Requis";
    },
    getTechniciansToString() {
      // v for
      const finalTechnicians = [];
      this.form.technicianIds.forEach((id) => {
        finalTechnicians.push(this.getNameById(id, this.technicians));
      });
      return finalTechnicians.toString().replace(",", ", ");
    },
    validateForm() {
      if (this.$refs.form.validate() === true) {
        this.e1 += 1;
      }
    },
    showModale() {
      this.show = true;
    },
    mapUserInformations(user) {
      return {
        name: user.firstname + " " + user.lastname + " (" + user.email + ")",
        id: user.id,
      };
    },
    createCampaign() {
      this.loading = true;
      this.form["workspaceId"] = this.workspaceId;

      if (this.campaignType === "Auscultation") {
        this.$api.auscultationCampaigns
          .create(this.form)
          .then((campaign) => {
            this.show = false;
            this.$store.commit("campaigns/addCampaign", campaign);
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      } else {
        this.$api.coringCampaigns
          .create({ ...this.form, defaultCoreParams: this.defaultCoreParams })
          .then((campaign) => {
            this.show = false;
            this.$store.commit("campaigns/addCampaign", campaign);
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
    },
    getNameById(id, users) {
      const user = users.find((user) => user.id === id);

      if (typeof user !== "undefined") {
        return user.name || "//";
      }
    },
    mounted() {
      this.$root.$on("closeCurrent", () => {
        this.show = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.right_btn {
  float: right;

  &:focus {
    background-color: red;
  }
}

.v-dialog {
  overflow-y: inherit !important;
}
.v-stepper__header {
  box-shadow: none !important;
}

.primary-step .primary {
  background-color: $brown !important;
  border-color: $brown !important;
}

.v-icon.v-icon::after {
  left: auto;
  transform: none !important;
}
</style>
