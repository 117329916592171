var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"campaign_card mt-5",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"},on:{"click":_vm.redirectToCampaignView}},[_c('v-list-item',[_c('v-list-item-content',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-overline mb-3"},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNewChipDisplayed),expression:"isNewChipDisplayed"}],staticClass:"mr-2",attrs:{"small":"","color":"blue","outlined":""}},[_vm._v(_vm._s(_vm.$t("new")))]),_vm._v(" "+_vm._s(_vm.moment(_vm.campaign.createdAt, "YYYY-MM-DDTHH:mm:ss+Z").format("LL"))+" ")],1),_c('v-list-item-title',{staticClass:"text-h5 mb-3"},[_c('b',[_vm._v(_vm._s(_vm.$cap(_vm.campaign.name)))])]),_c('v-list-item-subtitle',{staticClass:"mb-4"},[(_vm.campaign.type === 'coring')?_c('v-chip',{attrs:{"color":_vm.typeCampaignColor}},[_c('b',[_vm._v(_vm._s(_vm.$t(_vm.campaign.coringType)))])]):_c('v-chip',{attrs:{"color":_vm.typeCampaignColor}},[_c('b',[_vm._v(_vm._s(_vm.$t(_vm.campaign.type)))])]),(
                _vm.campaign.type === 'auscultation' &&
                _vm.campaign.operatingMode !== 'not_standard' &&
                _vm.campaign.operatingMode !== null
              )?_c('v-chip',{staticClass:"ml-5"},[_c('b',[_vm._v(_vm._s(_vm.$t(_vm.campaign.operatingMode)))])]):_vm._e()],1),_c('p',[_vm._v(_vm._s(_vm.campaign.description))])],1)],1)],1),_c('v-col',{staticClass:"campaign_card__step_bar_container",attrs:{"cols":"4"}},[_c('campaign-step-bar',{attrs:{"type":_vm.campaign.type,"currentStep":_vm.campaign.status,"campaignId":_vm.campaign.id}})],1),_c('div',{staticClass:"action_box"},[(_vm.$hasRight('campaigns.editCampaignBtn'))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"m_brown"},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,2760541864)},[_c('v-list',{staticClass:"pa-0",attrs:{"width":"25vh"}},[_c('v-list-item',{attrs:{"to":{ name: 'manage-campaign', params: { id: _vm.campaign.id } }}},[_c('v-list-item-title',[_vm._v("Éditer")])],1),(
              _vm.$hasRight('campaigns.editCampaignBtn') &&
              (_vm.campaign.status === 'awaiting_validation' ||
                _vm.campaign.status === 'programing_validated' ||
                _vm.campaign.status === 'taking_validated' ||
                _vm.campaign.status === 'taken')
            )?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.campaign.status === 'awaiting_validation' ||
              _vm.campaign.status === 'programing_validated'
                ? function () {}
                : _vm.showProvidersModal()}}},[(
                _vm.campaign.status === 'awaiting_validation' ||
                _vm.campaign.status === 'programing_validated'
              )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"disabledListItem",on:{"click":function () {}}},'v-list-item-title',attrs,false),on),[_vm._v("Paramétrer les prestations")])]}}],null,false,228424022)},[_c('span',[_vm._v("Les prélèvements doivent préalablement être effectués")])]):_c('v-list-item-title',[_vm._v("Paramétrer les prestations")])],1):_vm._e()],1)],1):_vm._e()],1)],1),(_vm.isProviderModaleOpen)?_c('ProviderModale',{attrs:{"agency":_vm.campaign.workspace.agency,"campaign":_vm.campaign.id},on:{"close":function($event){_vm.isProviderModaleOpen = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }