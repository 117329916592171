<template>
  <div id="project" class="pl-4 pt-4">
    <v-card
      class="d-flex align-center justify-space-between mb-1 pr-4"
      flat
      tile
    >
      <v-btn class="ml-2 mr-2 flex-grow-0 m_brown" icon :to="back">
        <v-icon large>mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-card flat class="flex-grow-1">
        <h2>{{ project.name }}</h2>
        <p class="m_subtitle">{{ project.description }}</p>
      </v-card>

      <v-card
        flat
        v-if="
          $store.state.campaigns.all.length > 0 || campaignsInProject === true
        "
      >
        <HighButtonSlot
          v-if="$hasRight('campaigns.createCampaignBtn') && !project.isArchived"
          class="mr-2"
          @click="showModale()"
        >
          Créer une nouvelle campagne
        </HighButtonSlot>
      </v-card>
    </v-card>

    <v-card
      flat
      class="lighten-4 mb-1 pr-4"
      style="width: 100%"
      v-if="campaigns.length > 0 || campaignsInProject === true"
    >
      <v-card flat color="transparent">
        <div class="align-center justify-space d-flex pl-2">
          <div class="ml-1 mr-2 filter">
            <v-text-field
              append-icon="mdi-magnify"
              clearable
              hide-details
              :label="$t('search_campaign')"
              :placeholder="$t('search_campaign')"
              v-model="filterName"
              @change="fetchCampaigns"
              @keydown.enter="fetchCampaigns"
              @click:append="fetchCampaigns"
              @click:clear="clearSearchName"
              class="pt-0"
            >
            </v-text-field>
          </div>
          <div class="ml-2 mr-2 autocomplete-status-filter_container">
            <v-select
              v-model="filterStatus"
              :items="statuses"
              :label="$t('filter_status')"
              multiple
              class="autocomplete-status-filter"
              hide-details
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  close
                  close-icon="mdi-close"
                  @click:close="removeChip(item)"
                  small
                >
                  <span>{{ $t(item) }}</span>
                </v-chip>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon style="color: inherit">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("all") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item ripple v-bind="attrs" v-on="on">
                  <v-list-item-action>
                    <v-icon v-if="filterStatus.indexOf(item) === -1">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon v-else>mdi-checkbox-marked</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="$t(item)"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <div class="d-flex flex-grow-1 flex-row-reverse pa-0 mr-3 filter">
            <MediumButtonSlot
              v-if="isResetButtonDisplayed"
              class="p-2"
              @click="resetFilters"
            >
              Reinitialiser les filtres
            </MediumButtonSlot>
          </div>
        </div>
      </v-card>
    </v-card>

    <div
      class="d-flex-column flex-grow-1 align-content-start align-start scrollable_container pr-4"
      v-if="$store.state.campaigns.all.length"
    >
      <v-row v-for="(campaign, index) in campaigns" :key="index" class="ma-0">
        <v-col cols="12" class="pa-2 pb-0 pt-0"
          ><campaign-card
            :campaign="campaign"
            @showProvidersModal="showProviders"
        /></v-col>
      </v-row>
    </div>

    <v-card
      flat
      class="grey lighten-4 center pa-5 vertical"
      style="display: block"
      v-else-if="campaignsInProject === false && loading === false"
    >
      <v-icon large>mdi-database-off-outline</v-icon>
      <h3 class="grey--text text--darken-1">Aucune campagne</h3>
      <br />
      <p v-if="!project.isArchived" class="grey--text text--darken-1">
        Votre projet ne contient pas encore de campagne.
      </p>
      <br />
      <HighButtonSlot
        v-if="$hasRight('campaigns.createCampaignBtn') && !project.isArchived"
        @click="showModale()"
      >
        Créer une nouvelle campagne
      </HighButtonSlot>
    </v-card>

    <campaign-creation-modale ref="modal" :workspaceId="$route.params.id" />
  </div>
</template>

<script>
import CampaignCard from "../../components/campaign/Campaign.card.vue";
import CampaignCreationModale from "../../components/campaign/CreateCampaign.modal.vue";

export default {
  components: {
    CampaignCard,
    CampaignCreationModale
  },
  name: "Campaign",
  data() {
    return {
      campaigns: [],
      project: {},
      showRightMenu: false,
      dialog: false,
      sortByName: [
        { text: "A-Z", value: "ASC" },
        { text: "Z-A", value: "DESC" }
      ],
      statuses: [],
      loading: true,
      filterName: "",
      filterStatus: [],
      filterStatusQuery: "",
      campaignsInProject: false
    };
  },
  mounted() {
    this.fetchCampaigns();
    this.fetchCampaignType();
  },
  computed: {
    isResetButtonDisplayed() {
      return (
        (this.filterName && this.filterName.length > 0) ||
        this.filterStatus.length > 0
      );
    },
    back() {
      if (this.project.isArchived)
        return {
          name: "workspaces",
          params: { archived: "archived" }
        };
      else return { name: "workspaces" };
    },
    icon() {
      if (this.filterStatus.length === 0) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    filterStatus() {
      this.concatenateStatus();
      this.fetchCampaigns();
    }
  },
  methods: {
    clearSearchName() {
      this.filterName = null;
      this.fetchCampaigns();
    },
    showModale() {
      this.$refs.modal.showModale();
    },
    fetchCampaigns() {
      let paramsCampaigns = {};
      if (this.filterName) paramsCampaigns.query = this.filterName;
      if (this.filterStatusQuery)
        paramsCampaigns.status = this.filterStatusQuery;

      this.$api.campaigns
        .findByWorkspace(this.$route.params.id, paramsCampaigns)
        .then(campaigns => {
          this.$store.commit("campaigns/setCampaigns", campaigns);
          this.campaigns = campaigns;
          if (campaigns.length > 0) {
            this.campaignsInProject = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
      if (this.project === {}) {
        this.$api.workspaces.findById(this.$route.params.id).then(workspace => {
          this.project = workspace;
        });
      }
    },
    fetchCampaignType() {
      this.$api.campaigns.getAvailableStatuses("coring").then(statuses => {
        this.statuses = statuses;
      });
      this.$api.workspaces.findById(this.$route.params.id).then(workspace => {
        this.project = workspace;
      });
    },
    resetFilters() {
      this.filterName = "";
      this.filterStatus = [];
      this.filterStatusQuery = "";
      this.fetchCampaigns();
    },
    concatenateStatus() {
      if (this.filterStatus !== "") {
        this.filterStatusQuery = "";
        this.filterStatus.forEach(element => {
          if (this.filterStatusQuery !== "") {
            this.filterStatusQuery += ",";
          }
          this.filterStatusQuery += element;
        });
      }
    },
    removeChip(value) {
      return (this.filterStatus = this.filterStatus.filter(function (e) {
        return e !== value;
      }));
    },
    showProviders(campaignId) {
      this.$refs.modalPresta.showModale(campaignId);
    },
    toggle() {
      this.$nextTick(() => {
        this.filterStatus = [];
      });
    }
  }
};
</script>

<style lang="scss">
#project {
  height: 100%;
  display: flex;
  flex-direction: column;

  .filter {
    width: 24%;
  }

  .center {
    text-align: center;
  }
  .m_subtitle {
    font-family: "Open Sans", sans-serif;
  }

  .autocomplete-status-filter_container {
    width: 24%;
  }
  .autocomplete-status-filter {
    width: 100%;
    input::placeholder {
      color: black;
    }
  }
  .v-text-field {
    padding-top: 0px;
  }
}
</style>
