<template>
  <v-card class="campaign_card mt-5" outlined>
    <v-row>
      <v-col cols="8" @click="redirectToCampaignView">
        <v-list-item>
          <v-list-item-content fluid>
            <div class="text-overline mb-3">
              <v-chip
                small
                v-show="isNewChipDisplayed"
                color="blue"
                class="mr-2"
                outlined
                >{{ $t("new") }}</v-chip
              >
              {{
                moment(campaign.createdAt, "YYYY-MM-DDTHH:mm:ss+Z").format("LL")
              }}
            </div>
            <v-list-item-title class="text-h5 mb-3">
              <b>{{ $cap(campaign.name) }}</b>
            </v-list-item-title>
            <v-list-item-subtitle class="mb-4">
              <v-chip
                :color="typeCampaignColor"
                v-if="campaign.type === 'coring'"
              >
                <b>{{ $t(campaign.coringType) }}</b>
              </v-chip>
              <v-chip :color="typeCampaignColor" v-else>
                <b>{{ $t(campaign.type) }}</b>
              </v-chip>
              <v-chip
                class="ml-5"
                v-if="
                  campaign.type === 'auscultation' &&
                  campaign.operatingMode !== 'not_standard' &&
                  campaign.operatingMode !== null
                "
              >
                <b>{{ $t(campaign.operatingMode) }}</b>
              </v-chip>
            </v-list-item-subtitle>
            <p>{{ campaign.description }}</p>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="4" class="campaign_card__step_bar_container">
        <campaign-step-bar
          :type="campaign.type"
          :currentStep="campaign.status"
          :campaignId="campaign.id"
        />
      </v-col>
      <div class="action_box">
        <v-menu v-if="$hasRight('campaigns.editCampaignBtn')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon class="m_brown">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0" width="25vh">
            <v-list-item
              :to="{ name: 'manage-campaign', params: { id: campaign.id } }"
            >
              <v-list-item-title>Éditer</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.stop="
                campaign.status === 'awaiting_validation' ||
                campaign.status === 'programing_validated'
                  ? () => {}
                  : showProvidersModal()
              "
              v-if="
                $hasRight('campaigns.editCampaignBtn') &&
                (campaign.status === 'awaiting_validation' ||
                  campaign.status === 'programing_validated' ||
                  campaign.status === 'taking_validated' ||
                  campaign.status === 'taken')
              "
            >
              <v-tooltip
                left
                v-if="
                  campaign.status === 'awaiting_validation' ||
                  campaign.status === 'programing_validated'
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title @click="() => {}" v-on="on" v-bind="attrs"
                     class="disabledListItem"
                    >Paramétrer les prestations</v-list-item-title
                  >
                </template>
                <span
                  >Les prélèvements doivent préalablement être effectués</span
                >
              </v-tooltip>
              <v-list-item-title v-else
                >Paramétrer les prestations</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <ProviderModale
      v-if="isProviderModaleOpen"
      :agency="campaign.workspace.agency"
      :campaign="campaign.id"
      @close="isProviderModaleOpen = false"
    />
  </v-card>
</template>

<script>
import ProviderModale from "@/components/providers/Providers.table.modal";
import CampaignStepBar from "./CampaignStepBar.vue";
export default {
  components: { CampaignStepBar, ProviderModale },
  name: "CampaignCard",
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isProviderModaleOpen: false,
      typeCampaignColor: "",
      isNewChipDisplayed: false,
    };
  },
  mounted() {
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    let createdDate = Date.parse(this.campaign.createdAt);
    this.isNewChipDisplayed = createdDate > oneWeekAgo;
  },
  methods: {
    redirectToCampaignView() {
      if (this.campaign.type === "auscultation") {
        this.$router.push({
          name: "auscultation-campaign-dashboard",
          params: { id: this.campaign.id },
        });
      } else {
        let routeName = "coring-campaign";
        if (this.campaign.status === "results_obtained")
          routeName += "-dashboard";
        this.$router.push({
          name: routeName,
          params: { id: this.campaign.id },
        });
      }
    },
    showProvidersModal() {
      this.isProviderModaleOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign_card {
  cursor: pointer;
  .action_box {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.campaign_card__step_bar_container {
  display: flex;
  justify-content: flex-end;
  padding-right: 70px;
  align-items: center;
}

.disabledListItem {
  color: gray;
  cursor: initial;
}
</style>
